<template>
  <div class="px-2 pt-2 pb-4 border-b border-gray-200 grid grid-cols-2 gap-4" :key="edited">
    <div>
      <div class="flex justify-between">
        <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
        <span class="text-sm text-red">Required</span>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          type="text"
          name="name"
          id="name"
          v-model="templateEdit.name"
          class="block w-full pr-10 border focus:outline-none sm:text-sm rounded-md border-gray-300"
          :class="
            nameError
              ? 'border-red placeholder-red text-red focus:ring-red-500 focus:border-red-500'
              : ''
          "
          placeholder="Pride Pull"
          @change="isTaken($event)"
        />
        <div
          v-if="nameError"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <font-awesome-icon icon="exclamation" class="h-5 w-5 text-red" />
        </div>
      </div>
      <p v-if="nameError" class="mt-2 text-sm text-red" id="email-error">
        {{ nameError }}
      </p>
    </div>
    <!-- Start Vendor -->
    <div>
      <div class="flex justify-between">
        <label for="vendor" class="block text-sm font-medium text-gray-700">Vendor</label>
        <span class="text-sm text-gray-500" id="vendor-optional">Optional</span>
      </div>
      <select
        id="vendor"
        v-model="templateEdit.vendor"
        class="mt-1 shadow-sm border focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        @change="altered = true"
      >
        <option v-for="vendor in vendors" :key="vendor._id">{{ vendor.name }}</option>
      </select>
    </div>
    <ListAdd
      label="Options"
      @addValue="addOption"
      @removeValue="removeOption"
      type="input"
      :editable="false"
      :list="templateEdit.options"
      :optional="false"
    />
    <ListAdd
      label="Categories"
      @addValue="addToCategories"
      @removeValue="removeFromCategories"
      type="select"
      :editable="false"
      :list="templateEdit.categories"
      :selectList="categories"
      :optional="true"
    />
    <ListAdd
      label="Subcategories"
      @addValue="addToSubcategories"
      @removeValue="removeFromSubcategories"
      type="select"
      :editable="false"
      :list="templateEdit.subcategories"
      :selectList="subcategories"
      :optional="true"
    />
    <ListAdd
      label="Tags"
      @addValue="addToTags"
      @removeValue="removeFromTags"
      type="select"
      :editable="false"
      :list="templateEdit.tags"
      :selectList="tags"
      :optional="true"
    />
    <div>
      <label for="type" class="block text-sm font-medium text-gray-700">Type</label>
      <div class="mt-1">
        <input
          type="text"
          name="type"
          id="type"
          v-model="templateEdit.type"
          class="shadow-sm border focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder=""
        />
      </div>
    </div>

    <div class="col-span-2 flex justify-end pt-2">
      <DualButton
        v-if="template"
        :options="buttonOptions"
        verticalPosition="top"
        @execute="handleButtonMethods($event)"
      />

      <button class="btn-primary rounded" @click="createProductTemplate" v-else>Create</button>
    </div>
  </div>
</template>

<script>
import ListAdd from '@/components/admin/shared/ListWAddItem.vue';
import DualButton from '@/components/shared/DualButton';

export default {
  data: function () {
    return {
      nameError: false,
      templateNull: {},
      edited: 0,
      newOption: null,
      buttonOptions: [
        {
          name: 'save',
        },
        { name: 'duplicate' },
        { name: 'clear selection' },
        {
          name: 'delete',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
        },
      ],
    };
  },
  props: {
    template: [Object, null],
  },
  computed: {
    templateEdit() {
      if (this.template) {
        return this.template;
      } else {
        return this.templateNull;
      }
    },
    vendors() {
      return this.$store.getters['tags/getTags']({ type: 'Vendor' });
    },
    categories() {
      return this.$store.getters['tags/getTags']({ type: 'Category' });
    },
    subcategories() {
      return this.$store.getters['tags/getTags']({
        type: 'Subcategory',
      });
    },
    tags() {
      const tagsObjects = this.$store.getters['tags/getTags']({
        type: 'Tag',
      });
      if (this.templateEdit.tags) {
        const tags = new Array();
        tagsObjects.forEach((el) => {
          if (!this.templateEdit.tags.includes(el.name)) tags.push(el.name);
        });
        return tags;
      } else {
        return tagsObjects;
      }
    },
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'save':
          this.saveProductTemplate();
          break;
        case 'duplicate':
          this.duplicateProductTemplate();
          break;
        case 'clear selection':
          this.$emit('clearSelected');
          break;
        default:
          this.deleteProductTemplate();
          break;
      }
    },
    addToCategories(value) {
      if (!this.templateEdit.categories) {
        this.templateEdit.categories = [];
      }
      this.templateEdit.categories.push(value);
      this.edited++;
    },
    removeFromCategories(name) {
      if (!confirm(`Are you sure you would like to remove category?`)) {
        return;
      }
      this.templateEdit.categories = this.templateEdit.categories.filter((el) => {
        return el !== name;
      });
      this.edited++;
    },
    addToSubcategories(value) {
      if (!this.templateEdit.subcategories) {
        this.templateEdit.subcategories = [];
      }
      this.templateEdit.subcategories.push(value);
      this.edited++;
    },
    removeFromSubcategories(name) {
      if (!confirm(`Are you sure you would like to remove ${name} from the subcategories?`)) {
        return;
      }
      this.templateEdit.subcategories = this.templateEdit.subcategories.filter((el) => {
        return el !== name;
      });
      this.edited++;
    },
    addToTags(tag) {
      if (!this.templateEdit.tags) {
        this.templateEdit.tags = [];
      } else if (this.templateEdit.tags.includes(tag)) {
        return;
      }

      this.templateEdit.tags.push(tag);
      this.edited++;
    },
    removeFromTags(name) {
      if (!confirm(`Are you sure you would like to remove ${name} from tags?`)) {
        return;
      }
      this.templateEdit.tags = this.templateEdit.tags.filter((el) => {
        return el !== name;
      });
      this.edited++;
    },
    removeOption(val) {
      if (!confirm(`Are you sure you would like to remove ${val} from the available options?`)) {
        return;
      }
      this.templateEdit.options = this.templateEdit.options.filter((el) => {
        return el !== val;
      });
      this.edited++;
    },
    addOption(value) {
      if (!this.templateEdit.options) {
        this.templateEdit.options = [];
      }
      this.templateEdit.options.push(value);
      this.edited++;
    },
    duplicateProductTemplate() {
      let error = null;
      if (!this.templateEdit.name) {
        error = 'There is an issue with the template name.';
      }
      if (!this.templateEdit.options || this.templateEdit.options.length == 0) {
        error = 'You must have at least 1 option. ';
      }
      if (error) {
        this.$store.commit('setError', {
          message: error,
          color: 'red',
          duration: 6500,
        });
        return;
      }

      this.$store.dispatch('productTemplates/duplicate', this.templateEdit);
      this.$emit('clearSelected');
    },
    saveProductTemplate() {
      let error = null;
      if (this.nameError || !this.templateEdit.name) {
        error = 'There is an issue with the template name.';
      }
      if (!this.templateEdit.options || this.templateEdit.options.length == 0) {
        error = 'You must have at least 1 option. ';
      }
      if (error) {
        this.$store.commit('setError', {
          message: error,
          color: 'red',
          duration: 6500,
        });
        return;
      }

      this.$store.dispatch('productTemplates/save', this.templateEdit);
    },
    createProductTemplate() {
      let error = null;
      if (this.nameError || !this.templateEdit.name) {
        error = 'There is an issue with the template name.';
      }
      if (
        !this.templateEdit ||
        !this.templateEdit.options ||
        this.templateEdit.options.length == 0
      ) {
        error = 'You must have at least 1 option.';
      }

      if (error) {
        this.$store.commit('setError', {
          message: error,
          color: 'red',
          duration: 6500,
        });
        return;
      }

      this.$store.dispatch('productTemplates/create', this.templateEdit);
      this.$emit('clearSelected');
    },
    deleteProductTemplate() {
      if (!confirm('Are you sure you would like to delete this product template?')) {
        return;
      }
      this.$store.dispatch('productTemplates/delete', this.templateEdit._id);
      this.$emit('clearSelected');
    },
    isTaken(event) {
      const val = this.$store.getters['productTemplates/getIsNameTaken']({
        name: event.target.value,
        template: this.templateEdit,
      });
      if (val) {
        this.nameError = 'This name is taken, please choose another name.';
      } else {
        this.nameError = false;
      }
    },
  },
  components: {
    ListAdd,
    DualButton,
  },
};
</script>

<style></style>
