<!--
This component is used for lists where you can add to the list from a predetermined list.
The following are the avaliable props.
    list: the list that you are adding values to.
    selectList: the list from where you are selecting.
    type: Whether there is a predetermined list or a free type add option. The acceptable values are input and select
    label: The label of the list
    editable: whether the editable option is being displayed.
    isError: if there is errorchecking on this then add an isError boolean
    optional: whether the field is optional.

This component emits the following.
    addValue
    addSelectedValue
    removeValue
-->

<template>
  <div>
    <div class="flex justify-between">
      <label for="name" class="block text-sm font-medium text-gray-700">{{ label }}</label>
      <button v-if="create" @click="editValue(null)" class="text-sm text-gray-500">+ Create</button>
      <span v-else-if="optional" class="text-sm text-gray-500">Optional</span>
      <span v-else class="text-sm text-red">Required</span>
    </div>
    <ul v-if="list">
      <li v-for="value in list" :key="value" class="relative mt-1 rounded-md shadow">
        <div class="mt-1 flex rounded-md shadow-sm">
          <div
            class="relative flex items-stretch flex-grow border rounded-none rounded-l-md p-2 sm:text-sm border-gray-300"
          >
            {{ value }}
          </div>
          <button
            v-if="editable"
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-300 bg-gray-50 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 focus:border-blue-500"
            @click="editValue(value)"
          >
            Edit
          </button>
          <button
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-300 bg-gray-50 hover:bg-red-300 hover:text-white focus:outline-none focus:ring-1"
            @click="removeValue(value)"
          >
            remove
          </button>
        </div>
      </li>
    </ul>
    <div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="mt-1 flex rounded-md shadow-sm" v-if="type === 'input'">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type="option"
              name="option"
              id="option"
              class="border focus:border-blue-500 block w-full rounded-none rounded-l-md px-2 sm:text-sm border-gray-300"
              placeholder="Color"
              v-model="newVal"
            />
          </div>
          <button
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-300 bg-gray-50 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 focus:border-blue-500"
            @click="addValue()"
          >
            <span>Add</span>
          </button>
        </div>
        <div v-else>
          <select
            class="shadow-sm border focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            id="subcategories"
            @change="addSelectValue($event)"
          >
            <option v-for="value in selectList" :key="value._id" class="my-1">
              {{ value.name }}
            </option>
          </select>
        </div>
        <div
          v-if="isError"
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        >
          <font-awesome-icon icon="exclamation" class="h-5 w-5 text-red" />
        </div>
      </div>
      <p v-if="isError" class="mt-2 text-sm text-red" id="email-error">
        Your password must be less than 4 characters.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      nameError: false,
      newVal: null,
    };
  },
  props: {
    list: { Array },
    selectList: { Array },
    type: { String },
    label: { String },
    editable: { Boolean, default: true },
    isError: { Boolean, default: false },
    optional: { Boolean, default: true },
    create: { Boolean, default: false },
  },
  computed: {},
  methods: {
    addValue() {
      this.$emit('addValue', this.newVal);
    },
    addSelectValue(event) {
      this.$emit('addValue', event.target.value);
    },
    removeValue(value) {
      this.$emit('removeValue', value);
    },
    editValue(value) {
      this.$emit('setEdit', { name: value, type: this.label });
    },
  },
};
</script>

<style></style>
