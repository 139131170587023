<template>
  <div class="m-2 flex justify-between">
    <!-- start category list -->
    <div class="flex flex-wrap" :class="selectedCategory ? 'w-1/2' : ''" id="categories">
      <div
        v-for="(category, index) in categories"
        :key="category._id"
        class="w-40 h-48 bg-white shadow-md mx-1 sm:mx-3 overflow-hidden my-4 rounded-xl font-medium flex flex-col"
      >
        <div
          class="w-full h-32 overflow-hidden mx-auto flex items-center justify-center"
          draggable
          @dragstart="startDrag($event, category)"
          @drop="onDrop($event, index)"
          @dragover.prevent
          @dragenter.prevent
        >
          <img v-lazy="category.image" :alt="`Image for ${category.name}`" class="object-cover" />
        </div>
        <button
          class="px-2 text-center flex-grow flex items-center justify-center whitespace-pre-wrap rounded-b-xl"
          @click="selectCategory(category)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>
    <!-- end category list -->

    <!-- start category edit List -->
    <div v-if="selectedCategory" class="w-1/2">
      <div class="bg-gray-300 p-2 rounded flex">
        <div
          class="bg-white shadow-md mx-1 sm:mx-3 overflow-hidden my-4 rounded-xl font-medium flex flex-col"
        >
          <div
            class="w-full overflow-hidden mx-auto flex items-center justify-center"
            @click="showUploader = true"
          >
            <img
              v-lazy="selectedCategory.image"
              :alt="`Image for ${selectedCategory.name}`"
              class="object-cover h-48"
            />
          </div>
          <div
            class="p-2 text-center flex-grow flex items-start justify-between whitespace-pre-wrap"
          >
            <div class="gap-1">
              <div class="flex items-center mb-2">
                <label for="retail_order" class="mr-2">Retail </label>
                <input
                  id="retail_order"
                  type="number"
                  v-model="selectedCategory.orderRetail"
                  class="border block rounded-md px-2 sm:text-sm border-gray-300 shadow w-24 no-arrows"
                />
              </div>
              <div class="flex items-center">
                <label for="retail_order" class="mr-2">Commercial </label>
                <input
                  id="commercial_order"
                  type="number"
                  v-model="selectedCategory.orderOther"
                  class="border block rounded-md px-2 sm:text-sm border-gray-300 shadow w-24 no-arrows"
                />
              </div>
            </div>
            <input
              type="text"
              v-model="selectedCategory.name"
              class="border focus:border-blue-500 shadow block rounded-md px-2 sm:text-sm border-gray-300 text-center"
            />
          </div>
          <button
            class="w-full bg-primary text-white text-lg p-2"
            @click="saveTag(selectedCategory)"
          >
            Save
          </button>
        </div>
        <!-- end category edit List -->

        <!-- Start subcategory edit -->
        <div
          v-if="selectedSubcategory"
          class="w-1/2 bg-white shadow-md mx-1 sm:mx-3 overflow-hidden my-4 rounded-xl font-medium flex flex-col"
        >
          <div class="w-full overflow-hidden mx-auto flex items-center justify-center">
            <img
              v-lazy="selectedSubcategory.image"
              :alt="`Image for ${selectedSubcategory.name}`"
              class="object-cover h-48"
            />
          </div>
          <div
            class="p-2 text-center flex-grow flex items-start justify-between whitespace-pre-wrap"
          >
            <div class="gap-1">
              <div class="flex items-center mb-2">
                <label for="retail_order" class="mr-2">Retail </label>
                <input
                  id="retail_order"
                  type="number"
                  v-model="selectedSubcategory.orderRetail"
                  class="border block rounded-md px-2 sm:text-sm border-gray-300 shadow w-24 no-arrows"
                />
              </div>
              <div class="flex items-center">
                <label for="retail_order" class="mr-2">Commercial </label>
                <input
                  id="commercial_order"
                  type="number"
                  v-model="selectedSubcategory.orderOther"
                  class="border block rounded-md px-2 sm:text-sm border-gray-300 shadow w-24 no-arrows"
                />
              </div>
            </div>
            <input
              type="text"
              v-model="selectedSubcategory.name"
              class="border shadow focus:border-blue-500 block rounded-md px-2 sm:text-sm border-gray-300 text-center"
            />
          </div>
          <button
            class="w-full bg-primary text-white text-lg p-2"
            @click="saveSubcategory(selectedSubcategory)"
          >
            Save
          </button>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="category in subcategories"
          :key="category._id"
          class="w-40 h-48 bg-white shadow-md mx-1 sm:mx-3 overflow-hidden my-4 rounded-xl font-medium flex flex-col"
        >
          <div class="w-full h-32 overflow-hidden mx-auto flex items-center justify-center">
            <img v-lazy="category.image" :alt="`Image for ${category.name}`" class="object-cover" />
          </div>
          <button
            class="px-2 text-center flex-grow flex items-center justify-center whitespace-pre-wrap rounded-b-xl"
            @click="selectSubcategory(category)"
          >
            {{ category.name }}
          </button>
        </div>
      </div>
    </div>
    <PopUp v-if="showUploader" @close="showUploader = false">
      <Uploader
        :variant="true"
        @setValues="setVariantImage"
        @uploadFinished="showUploader = false"
        @close="showUploader = false"
      ></Uploader>
    </PopUp>
  </div>
</template>

<script>
import Uploader from '@/components/shared/Uploader';
import PopUp from '@/components/shared/DesktopModal';

export default {
  data() {
    return {
      selectedCategory: null,
      selectedSubcategory: null,
      showUploader: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters['tags/getTags']({ type: 'Category' });
    },
    subcategories() {
      return this.$store.getters['tags/getTags']({
        type: 'Subcategory',
        value: this.selectedCategory.name,
      });
    },
  },
  methods: {
    selectCategory(category) {
      if (this.selectedCategory && this.selectedCategory._id === category._id) {
        this.selectedCategory = null;
      } else {
        this.selectedCategory = category;
      }
    },
    selectSubcategory(subcategories) {
      if (this.selectedSubcategory && this.selectedSubcategory._id === subcategories._id) {
        this.selectedSubcategory = null;
      } else {
        this.selectedSubcategory = subcategories;
      }
    },
    saveSubcategory(item) {
      if (!item.category) {
        this.$store.commit('setError', {
          message: "Can't save subcategory without a category.",
          color: 'red',
        });
        return;
      }
      this.saveTag(item);
    },

    saveTag(item) {
      if (item.name === null || item.type === null) {
        this.$store.commit('setError', {
          message: 'Tag Name needs to be filled out.',
          color: 'red',
        });
        return;
      }
      this.$store.dispatch('tags/addNewTag', item);
    },
    setVariantImage(value) {
      this.selectedCategory.image = value.path;
      this.showUploader = false;
    },
    startDrag(evt, item) {
      console.log(item);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', item._id);
    },
    onDrop(evt, index) {
      const labelId = evt.dataTransfer.getData('itemID');
      console.log(labelId);
      console.log(index);
      console.log(evt);
      // this.$store.commit('tag/alterOrderOfTag', {
      //   labelId,
      //   index,
      //   name: this.selectedFilterCategory,
      // });
    },
  },
  components: {
    Uploader,
    PopUp,
  },
};
</script>

<style></style>
