<template>
  <main class="relative pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
    <div class="bg-white rounded-lg shadow overflow-hidden">
      <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside class="py-6" :class="$route.name == 'Filters' ? '' : 'lg:col-span-2'">
          <SiteNavigation />
        </aside>

        <div
          class="divide-y divide-gray-200 lg:col-span-10"
          :class="$route.name == 'Filters' ? 'lg:col-span-11' : 'lg:col-span-10'"
        >
          <div class="relative bg-primary pb-25 overflow-hidden">
            <div
              class="inset-y-0 absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0"
              aria-hidden="true"
            >
              <svg
                class="flex-shrink-0 -mt-32"
                width="1750"
                height="308"
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path opacity=".75" d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#00A0B1" />
                <path opacity=".75" d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#005785" />
              </svg>
              <div class="flex-grow bg-light-blue-800 bg-opacity-75"></div>
            </div>
            <header class="relative py-5">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold text-white">{{ routeName }}</h1>
              </div>
            </header>
          </div>

          <router-view :admin="admin" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SiteNavigation from './SiteNavigation';
export default {
  data: function () {
    return {};
  },
  props: {},
  computed: {
    admin() {
      return this.$store.getters.getAdmin;
    },
    routeName() {
      const route = this.$route.name;
      if (route === 'Filters') {
        return `${route}: ${this.$route.query.filter}`;
      }
      return route;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
  components: {
    SiteNavigation,
  },
};
</script>

<style></style>
