<template>
  <div class="">
    <div class="flex p-4">
      <div>
        <input type="date" class="styled rounded shadow mr-2" v-model="startDate" />
        <input type="date" class="styled rounded shadow mx-2" v-model="endDate" />
      </div>
      <button class="rounded-l px-2 py-1 bg-primary text-white ml-2" @click="fetchData()">
        Custom Range
      </button>
      <button
        class="rounded-r px-2 py-1 bg-white text-primary border-primary border mr-2"
        @click="fetchDataForTwoWeeks()"
      >
        Last 2 weeks
      </button>
    </div>
    <div v-if="loading">
      <Loading />
    </div>
    <div v-if="incorrectPricing" class="grid grid-cols-12 text-primary font-bold">
      <div class="col-span-2">Company Name</div>
      <div class="col-span-3">Product Name</div>
      <div class="">Product Number</div>
      <div class="">Order Number</div>
      <div class="">Date</div>
      <div class="">MSRP</div>
      <div class="">Charged Price</div>
      <div class="">Correct Price</div>
    </div>
    <div
      v-for="(dataObject, index) of incorrectPricing"
      :key="index"
      :class="index % 2 == 0 ? 'bg-gray-200' : ''"
      class="grid grid-cols-12"
    >
      <div class="col-span-2">
        {{ dataObject.companyName }}
      </div>
      <div class="col-span-3">
        {{ dataObject.productName }}
      </div>
      <div class="flex flex-1">
        <router-link :to="`/admin/products/${dataObject.productId}`">
          {{ dataObject.productNum }}
        </router-link>
      </div>
      <div class="flex flex-1">
        <router-link :to="`/admin/orders/${dataObject.orderId}`">
          {{ dataObject.orderNumber }}
        </router-link>
      </div>
      <div class="flex flex-1">{{ dataObject.orderDate.substring(0, 10) }}</div>
      <div class="flex flex-1">${{ dataObject.msrp }}</div>
      <div class="flex flex-1">${{ dataObject.chargedPrice }}</div>
      <div class="flex flex-1">${{ dataObject.correctPrice }}</div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';

export default {
  data: function () {
    return {
      startDate: null,
      endDate: null,
    };
  },
  props: {},
  computed: {
    incorrectPricing: {
      get() {
        return this.$store.getters['analytics/getIncorrectUserProducts'];
      },
    },
    loading: {
      get() {
        return this.$store.getters['analytics/getLoading'];
      },
    },
  },
  watch: {},
  methods: {
    fetchData() {
      if (this.startDate && this.endDate) {
        this.$store.dispatch(
          'analytics/reconcilePricing',
          `startDate=${this.startDate}&endDate=${this.endDate}`
        );
      } else {
        this.$store.commit('setError', {
          color: 'red',
          message: 'You must select a start and end date',
        });
      }
    },
    fetchDataForTwoWeeks() {
      const today = new Date();
      const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      today.setDate(-14);
      const priorDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      this.$store.dispatch(
        'analytics/reconcilePricing',
        `startDate=${priorDate}&endDate=${todayString}`
      );
    },
  },
  mounted() {},
  components: {
    Loading,
  },
};
</script>

<style></style>
