<template>
  <form
    @submit.prevent="saveFilter"
    class="flex flex-col shadow-lg rounded-md overflow-hidden text-center"
    :class="isSelected ? 'bg-gray-200' : ''"
  >
    <div class="flex-1 flex flex-col p-3 relative">
      <div class="flex flex-grow-0 mx-auto" v-if="selectedFilterCategory === 'Color'">
        <div
          class="h-32"
          :class="filter.hex2 ? 'w-16 rounded-l-full' : ' w-32 rounded-full'"
          :style="`background-color: #${filter.hex}`"
          alt=""
        />
        <div
          class="w-16 h-32 rounded-r-full"
          v-if="filter.hex2"
          :style="`background-color: #${filter.hex2}`"
          alt=""
        />
      </div>
      <h3 class="mt-6 text-gray-900 text-sm font-medium">{{ filter.label }}</h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dd class="text-gray-500 text-sm" v-if="filter.hex">{{ filter.hex }}</dd>
        <dd class="text-gray-500 text-sm" v-if="filter.hex2">{{ filter.hex2 }}</dd>
      </dl>
      <button
        @click="showEdit = !showEdit"
        class="inline-block transition-transform duration-200 absolute right-1 bottom-1 px-1 rounded-full"
        :style="flip(showEdit)"
        type="button"
      >
        <font-awesome-icon icon="chevron-down" class="-mb-px" />
      </button>
    </div>
    <div v-if="showEdit">
      <div class="flex px-2 items-center border-b border-gray-300 focus-within:border-indigo-600">
        <label :for="`${filter.name}-${filter._id}`"> label: </label>
        <input
          name="label"
          :id="`${filter.label}-${filter._id}`"
          class="block w-full border-0 border-b border-transparent bg-transparent focus:border-blue-600 focus:ring-0 sm:text-sm p-1"
          type="text"
          v-model="filter.label"
        />
      </div>
      <div
        class="flex px-2 items-center mt-1 border-b border-gray-300"
        v-if="selectedFilterCategory === 'Color'"
      >
        <label :for="`${filter.hex}-${filter._id}`"> color: </label>
        <input
          name="color"
          :id="`${filter.hex}-${filter._id}`"
          class="block w-full border-0 border-b border-transparent bg-transparent focus:border-blue-600 focus:ring-0 sm:text-sm p-1"
          type="text"
          v-model="filter.hex"
        />
      </div>
      <div
        class="flex px-2 items-center mt-1 border-b border-gray-300"
        v-if="selectedFilterCategory === 'Color'"
      >
        <label :for="`${filter.hex2}-${filter._id}`"> color 2: </label>
        <input
          name="color"
          :id="`${filter.hex2}-${filter._id}`"
          class="block border-0 border-b border-transparent bg-transparent focus:border-blue-600 focus:ring-0 sm:text-sm p-1"
          type="text"
          v-model="filter.hex2"
        />
      </div>
      <div class="flex pl-2 items-center mt-1 border-b border-gray-300">
        <input
          type="text"
          v-model="newValue"
          class="block w-full border-0 border-b border-transparent bg-transparent focus:border-blue-600 focus:ring-0 sm:text-sm p-1"
        />
        <button
          type="button"
          class="hover:bg-primary hover:text-white rounded px-1"
          @click="addOption()"
        >
          add
        </button>
      </div>
      <div class="flex flex-wrap gap-2 p-2" :key="optionsKey">
        <button
          v-for="option in filter.options"
          :key="option"
          @click="removeOption(option)"
          type="button"
          class="px-1 border rounded border-gray-300 text-center relative hover:bg-red-300 hover:text-red"
        >
          {{ option }}
        </button>
      </div>
    </div>
    <div class="-mt-px flex divide-x divide-gray-200 border-t">
      <div class="w-0 flex-1 flex">
        <button
          type="button"
          @click="$emit('setSelectedFilter', filter)"
          class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
        >
          <!-- <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" /> -->
          <span class="ml-3">select</span>
        </button>
      </div>
      <div class="-ml-px w-0 flex-1 flex">
        <button
          @click="saveFilter()"
          class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
        >
          <span class="ml-3">save</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      newValue: null,
      showEdit: false,
      optionsKey: 0,
    };
  },
  props: {
    filter: Object,
    selectedFilterCategory: String,
    isSelected: Boolean,
  },
  methods: {
    saveFilter() {
      this.$store.dispatch('filter/saveIndividualFilterModel', this.filter);
      this.$emit('setSelectedFilter', null);
    },
    addOption() {
      if (!this.newValue) {
        return;
      }
      if (this.filter.options.includes(this.newValue)) {
        this.$store.commit('setError', {
          text: 'This filter already has this value.',
          color: 'red',
        });
        this.newValue = null;
        return;
      }
      this.filter.options.push(this.newValue);
      this.newValue = null;
    },
    removeOption(value) {
      this.filter.options = this.filter.options.filter((el) => {
        return el != value;
      });
      this.optionsKey++;
    },
    flip(value) {
      if (value) {
        return 'transform:rotate(180deg);';
      }
      return '';
    },
  },
};
</script>

<style></style>
