<template>
  <div class="px-8">
    <div class="font-medium grid grid-cols-2 sm:grid-cols-3 p-2">
      <div>Title</div>
      <div class="hidden sm:block">Template</div>
      <div>Page</div>
    </div>
    <div
      v-for="(layout, index) in layouts"
      :key="layout._id"
      class="hover:bg-blue-100 cursor-pointer"
      :class="index % 2 ? 'bg-gray-100' : null"
    >
      <router-link :to="'/admin/layouts/' + layout._id" class="grid grid-cols-2 sm:grid-cols-3 p-2">
        <div>{{ layout.title }}</div>
        <div class="hidden sm:block">{{ layout.template }}</div>
        <div>{{ layout.page }}</div>
      </router-link>
    </div>
    <div class="flex justify-end my-4 px-2">
      <button
        @click="newLayout"
        class="right-0 top-0 bg-action px-3 py-1 -mr-2 -mt-2 text-white rounded"
      >
        <font-awesome-icon icon="plus" />
        New layout
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      loading: false,
    };
  },
  computed: {
    layouts() {
      return this.$store.state.layouts;
    },
  },
  methods: {
    newLayout() {
      this.$store.dispatch('admin/addLayout', {
        title: 'New Page',
      });
      setTimeout(() => {
        this.$store.dispatch('admin/getLayouts');
      }, 1000);
    },
  },
};
</script>

<style></style>
