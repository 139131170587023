<template>
  <div>
    <nav class="space-y-1 flex flex-col md:flex-row lg:flex-col justify-between">
      <router-link
        :to="`/admin/site-settings/`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        aria-current="page"
        :class="currentlySelected('Layouts')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Layouts')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <span class="truncate"> Layouts </span>
      </router-link>

      <router-link
        :to="`/admin/site-settings/company-information`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Company Information')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Company Information')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
            clip-rule="evenodd"
          />
        </svg>

        <span class="truncate"> Company information </span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/filters?filter=Color`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Filters')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Filters')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
            clip-rule="evenodd"
          />
        </svg>

        <span class="truncate"> Filters </span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/templates`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Templates')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Templates')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
          />
        </svg>
        <span class="truncate"> Templates </span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/maintenance`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Maintenance')"
      >
        <svg
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Maintenance')"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
          />
        </svg>

        <span class="truncate"> Maintenance</span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/categories`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Categories')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Categories')"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
          />
        </svg>
        <span class="truncate">Categories</span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/notifications`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Notifications')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Notifications')"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <span class="truncate">Notifications</span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/verify-user-pricing`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Verify User Pricing')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Verify User Pricing')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
          />
        </svg>

        <span class="truncate">User Pricing</span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/miscellaneous`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Miscellaneous')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Miscellaneous')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
          />
        </svg>

        <span class="truncate">Miscellaneous</span>
      </router-link>
      <router-link
        :to="`/admin/site-settings/coupons`"
        class="group border-l-4 px-3 py-2 flex items-center text-sm font-medium flex-grow"
        :class="currentlySelected('Coupons')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
          :class="currentlySelectedIcon('Coupons')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
          />
        </svg>

        <span class="truncate">Coupons</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    commercial: { type: Boolean, default: false },
  },
  computed: {
    name() {
      return this.$route.name;
    },
  },
  watch: {},
  methods: {
    currentlySelected(name) {
      return name === this.name
        ? 'bg-blue-100 border-action text-action hover:bg-teal-50 hover:text-primary'
        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900';
    },
    currentlySelectedIcon(name) {
      return name === this.name
        ? 'text-action group-hover:text-primary'
        : 'text-gray-400 group-hover:text-gray-500';
    },
  },
  mounted() {},
  components: {},
};
</script>
<style scoped></style>
