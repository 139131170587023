<template>
  <div class="px-6">
    <dl class="divide-y divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Clear and rebuild the search array</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            When you search for products at the top, there is a list of suggestions that the
            customer can use. This list comprises of every product name, product number, tag,
            category, subcategory, and vendor. Because there is so much data, the list is created
            once. The search list will not contain anything new unless it is updated by clicking the
            "Execute" button on the right.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-right">
            <button
              @click="resetGlobalSearchArray"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Rebuild the filters</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            This will begin the process of recreating all the static filters. Static filters are the
            filters that are used for subcategories and categories. Because viewing products in a
            subcategory or category will retrieve many products and the filter is supposed to
            accurately the products that are being displayed, the filter must be created after all
            products have been retrieved from the database. This takes a long time. So, this
            function will create them on the server, store them, and make them easy to retrieve when
            a customer searches the categories or subcategories. Rebuilding filters is one of the
            most important to do for your customers to easily find their products. It is recommended
            that you run this process at least once a day at the end of the day.
            <br />
            <br />
            Note, when you run this process, the filter will not represent all products that are in
            the relevant category or subcategory. If a product is not published when the filters are
            rebuilt, it will not be represented in the filter. If a product is published and one of
            the variants is not published, the variant will not be represented in the filter. This
            is only relevant if the unpublished product has a unique option. If the unpublished
            product is like another published product, the filter will be built using the other
            product.
            <br />
            <br />
            Searches that are done using the search bar at the top do not use static filters. The
            filter created when you search is dynamically created after all of the products have
            been retrieved from the server. Searching at the top will always retrieve more accurate
            results.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="resetFilters"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Rebuild xml file</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            The xml file is what helps our products be searchable on google, bing, and other search
            engines. Like the search list above, the xml file takes a long time to create. Because
            it takes a long time, you should only execute this in the evening or on weekends.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="buildXML"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Clear browser cache</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            Clearing the browser cache will only affect your browser, not other users. This will
            clear your browser cache so you can see new tags, categories, and subcategories.
            <br /><br />
            The reason clearing your browser cache is necessary is because tags (tags encompasses
            all tags, categories, subcategories, and vendors) are now being stored in your browser's
            cache. Tags are being stored in session storage because as of March 3rd, 2022, there are
            over 3,000 tags. Your browser retrieves these 3,000 tags every time you go to a new
            page. This is an unnecessary tax on server resources. Because the tags may change once
            or twice per day and it is not pertinent for other employee’s or admins to immediately
            see the new tags, the tags are now saved in your browser’s session storage. If you close
            the tab you are using, the session storage is cleared, or clicking the “Execute” button
            to the right will clear the cache.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="clearCache()"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Set suggested products</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            Setting the suggested products will go through every order to determine which products
            are frequently purchased together. This function will overwrite explicitly set suggested
            products.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="setSuggestions()"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Update category order</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow">
            Updating the category order will go through every order to determine which categories
            are most frequently used by commercial and retail users.
          </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="updateCategoryOrder()"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Execute
            </button>
          </span>
        </dd>
      </div>
      <!-- <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 md:grid-cols-5 sm:gap-4 items-center">
        <dt class="text-sm font-medium text-gray-500">Clean up numbers</dt>
        <dd
          class="mt-1 flex flex-col sm:flex-row text-sm text-gray-900 sm:mt-0 sm:col-span-2 md:col-span-4"
        >
          <span class="flex-grow"> This will go through all of your products and round the </span>
          <span class="ml-4 flex-shrink-0 text-center sm:text-righ">
            <button
              @click="clearNumbers"
              class="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              Execute
            </button>
          </span>
        </dd>
      </div> -->
    </dl>
  </div>
</template>

<script>
export default {
  methods: {
    clearCache() {
      window.sessionStorage.clear();
    },
    //clear the server's search array for the search suggestions for users. This should be used after a user adds a new category
    resetGlobalSearchArray() {
      this.$store.dispatch('clearSearchArray');
    },
    buildXML() {
      this.$store.dispatch('buildXML');
    },
    resetFilters() {
      this.$store.dispatch('filter/buildStaticFilters');
    },
    clearNumbers() {
      this.$store.dispatch('quickbooks/clearKeys');
    },
    setSuggestions() {
      this.$store.dispatch('products/setSuggested');
    },
    updateCategoryOrder() {
      this.$store.dispatch('tags/updateCategoryOrder');
    },
  },
};
</script>

<style></style>
