<template>
  <form @submit.prevent="CreateColor" class="flex justify-center gap-4">
    <div class="my-2">
      <Sketch :value="colorCreate" @input="updateFromPicker" v-show="true" />
      <div class="flex-grow">
        <label for="color" class="block text-sm font-medium text-gray-700">Color name</label>
        <div class="mt-1">
          <input
            type="text"
            name="color"
            id="color"
            v-model="colorName"
            class="shadow-sm border focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="brushed nickel"
          />
        </div>
      </div>
      <div class="text-center my-2 align-middle">
        <button class="text-white bg-primary py-1 px-2 rounded">create new color</button>
      </div>
    </div>
  </form>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  data: function () {
    return {
      colorCreate: {
        hex: '#000000',
      },
      colorValue: '',
      displayPicker: false,
      defaultColor: '#FF0000',
      // colors: colors,
      colorName: null,
    };
  },
  computed: {
    colors() {
      return this.$store.getters.getColors;
    },
  },
  methods: {
    CreateColor() {
      if (this.colorName) {
        this.$emit('createFilter', {
          label: this.colorName,
          hex: this.colorCreate.hex8,
          options: [],
        });
      } else {
        this.$store.commit('setError', {
          message: 'The color must have a name.',
          color: 'red',
        });
      }
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colorCreate = {
          hex: color.slice(1, color.length),
        };
      } else if (color.slice(0, 4) === 'rgba') {
        let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex =
            '#' +
            ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colorCreate = color;
      if (color.rgba.a === 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          'rgba(' +
          color.rgba.r +
          ', ' +
          color.rgba.g +
          ', ' +
          color.rgba.b +
          ', ' +
          color.rgba.a +
          ')';
      }
    },
  },
  components: {
    Sketch,
  },
};
</script>

<style></style>
