<template>
  <button
    @click="$emit('setTemplateEdit', template)"
    class="p-2 shadow rounded border hover:bg-action hover:text-white"
    :class="selected ? 'bg-primary text-white' : ''"
  >
    {{ template.name }}
  </button>
</template>

<script>
export default {
  props: {
    template: Object,
    selected: { Boolean, default: false },
  },
};
</script>

<style></style>
