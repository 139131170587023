<template>
  <div class="px-4 py-4 gap-y-4">
    <div class="flex text-xs overflow-x-auto">
      <div>
        <button
          type="button"
          @click="getFiltersWithUnassigned"
          class="bg-white rounded shadow p-2 my-4 mx-2 hover:bg-action hover:text-white whitespace-no-wrap"
        >
          get unassigned quantities
        </button>
      </div>
      <div v-for="[key, value] in filters" :key="value._id">
        <button
          type="button"
          @click="setSelectedCategory(key)"
          class="bg-white rounded shadow p-2 my-4 mx-2 whitespace-no-wrap overflow-hidden hover:bg-action hover:text-white"
          :class="selectedFilterCategory === key ? 'bg-primary text-white' : ''"
        >
          {{ key }}
          {{ value.unassignedTotal }}
        </button>
      </div>
    </div>
    <div class="flex gap-4 h-128">
      <div class="md:col-span-3 w-1/4">
        <ColorSwatch v-if="selectedFilterCategory == 'Color'" @createFilter="createFilter" />
        <form @submit.prevent="createNewLabel" v-else>
          <label for="filter-label" class="block text-sm font-medium text-gray-500">Label</label>
          <div class="mt-1">
            <input
              type="text"
              name="filter-label"
              id="filter-label"
              class="shadow-sm block w-full sm:text-sm border-gray-300 border rounded-md"
              placeholder="1 - 2 in"
              v-model="labelName"
            />
          </div>
          <button type="submit" class="bg-primary text-white rounded px-2 mt-2 ml-auto">
            Create label
          </button>
        </form>
      </div>
      <div class="w-3/4">
        <div class="flex justify-between align-middle">
          <div>
            <h3>Unassigned options</h3>
            <div class="flex flex-wrap gap-4 py-2 items-center">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  v-model="unassignedSearch"
                  class="border focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  placeholder="search..."
                />
              </div>
            </div>
          </div>
          <button type="button" @click="getData()">
            <font-awesome-icon icon="sync-alt" class="mr-1" />refresh
          </button>
        </div>
        <div class="h-full relative overflow-hidden">
          <Loading v-if="loading" :whiteSpace="false" class="h-96" />
          <div
            v-else-if="filteredUnassignedOptions.length > 0"
            class="flex flex-wrap gap-2 py-2 overflow-y-auto absolute h-96 max-h-3/4"
            :key="unassignedSearch"
          >
            <div v-for="option in filteredUnassignedOptions" :key="option">
              <button
                :disabled="!selectedFilterLabel"
                class="px-1 border rounded border-gray-300 text-center relative"
                :class="selectedFilterLabel ? 'hover:bg-green-300 hover:text-green-800' : ''"
                @click="addUnassignedOptionToFilter(option)"
              >
                {{ option }}
              </button>
            </div>
          </div>
          <div v-else>No unassigned options for {{ selectedFilterCategory }}</div>
        </div>
      </div>
    </div>
    <div class="border-t pb-4" v-if="filter">
      <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Assigned filters</h2>
      <div
        :key="orderChange"
        role="list"
        class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        <div
          v-for="(filter, index) in filter.filters"
          :key="filter._id"
          draggable
          @dragstart="startDrag($event, filter)"
          @drop="onDrop($event, index)"
          @dragover.prevent
          @dragenter.prevent
          class="rounded-md drag-el"
        >
          <IndividualFilter
            @setSelectedFilter="setSelectedFilter"
            :selectedFilterCategory="selectedFilterCategory"
            :filter="filter"
            :isSelected="filter.label === selectedFilterLabel"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-between lg:col-span-11">
      <div class="flex items-center">
        <label for="order" class="block text-sm font-medium text-gray-700">Order</label>
        <input
          type="number"
          id="order"
          v-model="filter.order"
          class="ml-1 block w-24 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <DualButton
        :options="options"
        verticalPosition="top"
        @execute="handleButtonMethods($event)"
        position="right"
      />
    </div>
    <PopUp
      v-if="showCreateFilterCategory"
      title="Create New Filter"
      class="text-left"
      @close="showCreateFilterCategory = false"
    >
      <div class="p-3 md:hidden">
        <h3 class="text-primary">Create New filter</h3>
      </div>
      <div class="text-gray-800 md:font-normal md:h-auto md:mb-4 p-4 text-left">
        This will create a new section in the product filter. Only do this if there is an option in
        products that is not currently available.
      </div>
      <form class="md:my-2 m-4" @submit.prevent="CreateNewFilterCategory">
        <div class="flex flex-col">
          <label for="filter-name" class="mb:mt-0 md:text-sm mt-2 mb-1">Filter name</label>
          <input
            class="styled border shadow"
            type="text"
            id="filter-name"
            required
            v-model="filterName"
            placeholder="Overall Length"
          />
        </div>
        <div class="text-right">
          <button class="btn-action md:my-4 md:ml-auto md:rounded rounded-full w-full mt-6">
            Create filter category
          </button>
        </div>
      </form>
    </PopUp>
  </div>
</template>

<script>
import IndividualFilter from './filter/IndividualFilter.vue';
import ColorSwatch from './filter/ColorSwatch';
import PopUp from '@/components/shared/PopUp';
import Loading from '@/components/shared/Loading';
import DualButton from '@/components/shared/DualButton';

export default {
  data: function () {
    return {
      selectedFilterCategory: 'Color',
      selectedFilterLabel: null,
      selectedOptions: null,
      labelName: null,
      showCreateFilterCategory: false,
      filterName: null,
      unassignedSearch: null,
      hasNotBeenSaved: false,
      orderChange: 0,
      options: [
        {
          name: 'Save Filter',
          buttonMethod: 'save',
        },
        {
          name: 'Create Filter',
          buttonMethod: 'create',
        },
        {
          name: 'Delete Filter',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
          buttonMethod: 'delete',
        },
      ],
    };
  },
  computed: {
    filter() {
      return this.$store.getters['filter/getSingleFilterTemplate'](this.selectedFilterCategory);
    },
    filters() {
      return this.$store.getters['filter/getFilterTemplates'];
    },
    unassignedOptions() {
      return this.$store.getters['filter/getUnassignedFilterOptions'];
    },
    filteredUnassignedOptions() {
      let val = this.unassignedOptions;
      val.sort();
      if (this.unassignedSearch != null && this.unassignedSearch != '')
        return val.filter((el) => el.includes(this.unassignedSearch));
      return val;
    },
    loading() {
      return this.$store.getters['filter/getUnassignedLoading'];
    },
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'save':
          this.saveFilter();
          break;
        case 'create':
          this.showCreateFilterCategory = !this.showCreateFilterCategory;
          break;
        default:
          this.deleteFilter();
          break;
      }
    },
    CreateNewFilterCategory() {
      this.$store.dispatch('filter/createNewFilterCategory', {
        name: this.filterName,
        filters: [],
      });
      this.filterName = null;
      this.showCreateFilterCategory = false;
      this.unassignedSearch = null;
    },
    createFilter(value) {
      value.parent_id = this.filter._id;
      this.unassignedSearch = null;
      this.$store.dispatch('filter/saveIndividualFilterModel', value);
    },
    createNewLabel() {
      this.createFilter({ label: this.labelName, options: [] });
      this.labelName = null;
      this.unassignedSearch = null;
    },
    deleteFilter() {
      this.$store.dispatch('filter/deleteFilterTemplate', this.filter._id);
      this.$router.push('/admin/site-settings/filters?filter=Color');
    },
    setSelectedFilter(filter) {
      if (this.hasNotBeenSaved === true && filter) {
        this.$store.commit('setError', {
          message: 'Before changing your selection, please save the label',
          color: 'red',
          duration: 6500,
        });
        return;
      }
      if (!filter) {
        this.hasNotBeenSaved = false;
        this.selectedFilterLabel = null;
      } else if (this.selectedFilterLabel === filter.label) {
        this.selectedFilterLabel = null;
      } else {
        this.selectedOptions = filter.options;
        this.selectedFilterLabel = filter.label;
      }
    },
    addUnassignedOptionToFilter(option) {
      this.hasNotBeenSaved = true;
      this.selectedOptions.push(option);
      this.$store.commit(
        'filter/setUnassignedFilterOptions',
        this.unassignedOptions.filter((el) => el != option)
      );
    },
    changeUrl() {
      this.$router
        .push({
          path: this.$route.name,
          query: {
            filter: this.selectedFilterCategory,
          },
        })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error);
          }
        });
    },
    getData() {
      this.$store.dispatch('filter/getUnassignedFilterOptions', this.selectedFilterCategory);
      this.unassignedSearch = null;
    },
    setSelectedCategory(value) {
      this.selectedFilterCategory = value;
      this.changeUrl();
    },
    getFiltersWithUnassigned() {
      this.$store.dispatch('filter/getUnassignedFiltersWithUnassociatedValues');
    },
    startDrag(evt, item) {
      console.log(item);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', item._id);
    },
    onDrop(evt, index) {
      const labelId = evt.dataTransfer.getData('itemID');
      this.$store.commit('filter/alterOrderOfLabel', {
        labelId,
        index,
        name: this.selectedFilterCategory,
      });
      this.orderChange++;
    },
    saveFilter() {
      this.$store.dispatch('filter/saveFilter', this.filter);
    },
  },
  mounted() {
    this.selectedFilterCategory = this.$route.query.filter;
    this.$store.dispatch('filter/getUnassignedFilterOptions', this.selectedFilterCategory);
    this.$store.dispatch('filter/getFilterTemplates');
  },

  components: {
    IndividualFilter,
    ColorSwatch,
    PopUp,
    Loading,
    DualButton,
  },
};
</script>

<style scoped>
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
