<template>
  <div class="p-4">
    <div class="border-gray-200">
      <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-baseline">
        <dt class="text-sm font-medium text-gray-500">Company address</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            name="address"
            id="address"
            class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
            v-model="siteSettings.address"
          />
        </dd>
      </div>
      <div
        class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-baseline border-t"
      >
        <dt class="text-sm font-medium text-gray-500">Company email</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <input
            type="email"
            name="email"
            id="email"
            class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
            v-model="siteSettings.email"
          />
        </dd>
      </div>
      <div
        class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-baseline border-t"
      >
        <dt class="text-sm font-medium text-gray-500">Company phone</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <input
            type="tel"
            name="phone"
            id="phone"
            class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
            v-model="siteSettings.phone"
          />
        </dd>
      </div>
      <div
        v-for="(day, index) in siteSettings.hoursOfOperation"
        :key="index"
        class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-baseline border-t"
      >
        <div class="text-sm font-medium text-gray-500">{{ getDay(index) }} hours</div>
        <div class="mt-1 text-sm text-gray-900 sm:mt-0 flex border items-baseline rounded">
          <label :for="`${index}-open`" class="text-sm font-medium text-gray-700 ml-2">
            open
          </label>
          <select
            :id="`${index}-open`"
            :name="`${index}-open`"
            v-model="siteSettings.hoursOfOperation[index].open"
            class="mt-1 block w-full pl-3 pr-10 py-1 border-gray-300 rounded-md text-sm focus:outline-none"
          >
            <option v-for="time in times" :key="`${time}-${index}-open`" class="text-black">
              {{ time }}
            </option>
          </select>
        </div>
        <div class="mt-1 text-sm text-gray-900 sm:mt-0 flex border items-baseline rounded">
          <label :for="`${index}-close`" class="text-sm font-medium text-gray-700 ml-2">
            close
          </label>
          <select
            :id="`${index}-close`"
            :name="`${index}-close`"
            v-model="siteSettings.hoursOfOperation[index].close"
            class="mt-1 block w-full pl-3 pr-10 py-1 border-gray-300 rounded-md text-sm focus:outline-none"
          >
            <option
              v-for="time in times"
              :key="`${time}-${index}-close`"
              :value="time"
              class="text-black"
            >
              {{ time }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-baseline border-t"
      >
        <div class="text-sm font-medium text-gray-500">Upcoming days off</div>
        <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            name="closed"
            id="closed"
            class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
            v-model="siteSettings.closed"
          />
        </div>
      </div>
    </div>
    <div class="text-right w-full px-8">
      <button class="text-white bg-primary py-1 px-2 rounded" @click="saveSiteSettings">
        Save
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      email: null,
      subject: null,
      message: null,
      emailSent: false,
      times: [
        'closed',
        '8:00',
        '8:30',
        '9:00',
        '9:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
      ],
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    getDay(index) {
      switch (index) {
        case 1:
          return 'Monday';
        case 2:
          return 'Tuesday';
        case 3:
          return 'Wednesday';
        case 4:
          return 'Thursday';
        case 5:
          return 'Friday';
        case 6:
          return 'Saturday';
        default:
          return 'Sunday';
      }
    },
    saveSiteSettings() {
      this.$store.dispatch('saveSiteSettings', this.siteSettings);
    },
    sendEmail() {
      this.$store.dispatch('contactUs', {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      });
      this.emailSent = true;
    },
    addToDayOff($event) {
      if (!this.siteSettings.closed) {
        this.siteSettings.closed = [];
      }

      if (this.siteSettings.closed.includes($event.target.value)) {
        this.siteSettings.closed = this.siteSettings.closed.filter(
          (el) => el != $event.target.value
        );
      } else {
        this.siteSettings.closed.push($event.target.value);
      }
    },
    removeDayOff(day) {
      this.siteSettings.closed = this.siteSettings.closed.filter((el) => el != day);
    },
  },
};
</script>

<style></style>
