<template>
  <div class="flex flex-col justify-between space-y-4 p-4">
    <div class="flex gap-4 flex-wrap">
      <div
        v-for="template in notificationTemplates"
        :key="template._id"
        class="rounded-lg bg-white shadow-md overflow-hidden"
      >
        <div
          class="font-bold hover:bg-gray-200 hover:text-black cursor-pointer p-4"
          :class="isSelected(template._id)"
          @click="setSelectedTemplate(template)"
        >
          {{ notificationValue(template.type) }}
        </div>
        <div class="p-4">
          <div v-for="user in template.users" :key="user" class="flex justify-between">
            <div>{{ getEmployeeName(user) }}</div>
            <div v-if="isSelected(template._id)">
              <button class="hover:bg-red-200 px-2 rounded" @click="removeUser(user)">
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <select
        v-if="selectedTemplate"
        v-model="selectedTemplate.type"
        class="shadow-md focus:border-blue-500 block sm:text-sm border-blue-300 rounded-md"
      >
        <option
          v-for="element in notificationTypes"
          :key="element.value"
          :value="element.value"
          class="block text-sm font-medium text-primary"
        >
          {{ element.key }}
        </option>
      </select>
      <div class="relative flex items-center" v-if="selectedTemplate">
        <label for="employee" class="block text-sm font-medium text-primary mr-4">employee</label>
        <div>
          <select
            id="salesman"
            class="shadow-md focus:border-blue-500 block sm:text-sm border-blue-300 rounded-md"
            @change="addEmployee($event)"
          >
            <option v-for="employee in employees" :key="employee._id" :value="employee._id">
              {{ employee.name }}
            </option>
          </select>
        </div>
      </div>
      <DualButton
        :options="options"
        verticalPosition="top"
        @execute="handleButtonMethods($event)"
        position="right"
      />
    </div>
  </div>
</template>

<script>
import DualButton from '@/components/shared/DualButton';

export default {
  data() {
    return {
      notificationTypes: [
        { key: 'New notification template', value: '0' },
        { key: 'Quickbooks product error', value: '1' },
        { key: 'Quickbooks order error', value: '2' },
        { key: 'Quickbooks user error', value: '3' },
        { key: 'User favorite error', value: '4' },
        { key: 'Product Error', value: '5' },
        { key: ' ', value: '6' },
        { key: 'Order marked as paid on QB', value: '7' },
        { key: 'Order paid', value: '8' },
        { key: 'User created', value: '9' },
        { key: ' ', value: '10' },
        { key: 'New review', value: '11' },
      ],
      selectedTemplate: null,
      options: [
        {
          name: 'Save template',
          buttonMethod: 'save',
        },
        {
          name: 'Delete template',
          buttonMethod: 'delete',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
        },
        {
          name: 'Create template',
          buttonMethod: 'create',
        },
      ],
    };
  },
  computed: {
    notificationTemplates() {
      return this.$store.getters['notifications/getNotificationTemplates'];
    },
    employees() {
      return this.$store.getters['users/getEmployees'];
    },
  },
  methods: {
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'save':
          this.saveTemplate();
          break;
        case 'delete':
          this.deleteTemplate();
          break;
        default:
          this.createTemplate();
          break;
      }
    },
    fetchData() {
      this.$store.dispatch('notifications/getNotificationTemplates', '?');
    },
    notificationValue(type) {
      return this.notificationTypes[type].key;
    },
    setSelectedTemplate(template) {
      if (this.selectedTemplate && template._id === this.selectedTemplate._id) {
        this.selectedTemplate = null;
      } else {
        this.selectedTemplate = template;
      }
    },
    isSelected(id) {
      if (this.selectedTemplate && this.selectedTemplate._id === id) {
        return 'bg-action text-white';
      }
    },
    getEmployeeName(id) {
      for (const user of this.employees) {
        if (user._id === id) {
          return user.name;
        }
      }
    },
    addEmployee(event) {
      if (this.selectedTemplate.users.includes(event.target.value)) {
        this.$store.commit('setError', {
          message: 'This user is already assigned to the notification.',
          color: 'red',
        });
        return;
      }
      this.selectedTemplate.users.push(event.target.value);
    },
    removeUser(id) {
      this.selectedTemplate.users = this.selectedTemplate.users.filter((el) => el != id);
    },
    saveTemplate() {
      this.$store.dispatch('notifications/updateNotificationTemplate', this.selectedTemplate);
    },
    createTemplate() {
      this.$store.dispatch('notifications/createNotificationTemplate', { type: 0 });
    },
    deleteTemplate() {},
  },
  components: {
    DualButton,
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style></style>
