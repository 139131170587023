<template>
  <div class="px-4 py-4 gap-y-4">
    <Edit :template="templateEdit" @clearSelected="templateEdit = null" />
    <div class="flex flex-wrap gap-4 py-2 items-center">
      <div class="mt-1 relative rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          type="text"
          name="search"
          id="search"
          v-model="search"
          class="border focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="search..."
        />
      </div>
      <div v-for="template in templates" :key="template._id">
        <ProductTemplate
          :template="template"
          @setTemplateEdit="setTemplateEdit"
          :selected="isSelected(template.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTemplate from './templates/ProductTemplate';
import Edit from './templates/TemplateEdit';

export default {
  data: function () {
    return {
      templateEdit: null,
      search: null,
    };
  },
  computed: {
    templates() {
      return this.$store.getters['productTemplates/getTemplates'](this.search);
    },
  },
  methods: {
    setTemplateEdit(template) {
      if (this.templateEdit && this.templateEdit._id === template._id) {
        this.templateEdit = null;
        return;
      }
      this.templateEdit = template;
    },
    fetchData() {
      this.$store.dispatch('productTemplates/get');
    },
    isSelected(name) {
      if (this.templateEdit && this.templateEdit.name === name) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.fetchData();
  },
  components: {
    ProductTemplate,
    Edit,
  },
};
</script>

<style></style>
